import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { BaseComponent } from './views/layout/base/base.component';
const routes: Routes = [
  {
    path:'',redirectTo:'auth',pathMatch:'full'
  },
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) }
  ,
  {
    path: 'base',
    component: BaseComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'catalog',
        loadChildren: () => import('./module/catalog/catalog.module').then(m => m.CatalogModule)
      },
      {
        path:'content-management',
        loadChildren:()=> import('./module/content-management/content-management.module').then(m=>m.ContentManagementModule)
      },
      {
        path:'sales',
        loadChildren:()=>import('./module/sales/sales.module').then(m=>m.SalesModule)
      },
      {
        path:'customers',
        loadChildren:()=>import('./module/customers/customers.module').then(m=>m.CustomersModule)
      }
    ]
  },
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  dateTime:Date;
  constructor() { }

  ngOnInit(): void {
    // timer(0,1000).subscribe(()=>{
    //   this.dateTime=new Date()
    // })
    this.dateTime=new Date()
  }

}

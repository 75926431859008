import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatherIconDirective } from './feather-icon.directive';
import { FeatherModule } from 'angular-feather';

const icons = {
};
@NgModule({
  declarations: [FeatherIconDirective
  ],
  imports: [
    CommonModule,
    FeatherModule,
    FeatherModule.pick(icons)
  ],
  exports: [FeatherIconDirective]
})
export class FeahterIconModule { }

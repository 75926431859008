import { AuthGuard } from 'src/app/core/guard/auth.guard';
import { MenuItem } from './menu.model';
export const MENU: MenuItem[] = [
  {
    label: 'Ecommerce',
    isTitle: true
  },

  {
    label: 'Dashboard',
    icon: 'home',
    link: "/base/dashboard",
  },
  {
    label: "Catalog",
    icon: "file-text",
    subItems: [
      {
        label: "Products",
        link: "/base/catalog/products",
      },
      {
        label: "Categories",
        link: "/base/catalog/categories",
      },
      {
        label: "Manufacturer",
        link: "/base/catalog/manufacturers",
      },
      {
        label: "Product Reviews",
        link: "/base/catalog/product-review",
      },
      {
        label:'Product Tags',
        link:"/base/catalog/product-tags"
      }
    ],
  },
  {
    label:'Sales',
    icon:'shopping-cart',
    subItems:[
      {
        label:'Orders',
        link:'/base/sales/orders'
      },
      {
        label:'Shipments',
        link:'/base/sales/shipments',
      },
      {
        label:'Return Request',
        link:'/base/sales/return-request',
      },
      {
        label:'Shopping Carts',
        link:'/base/sales/shopping-carts',
      },
    ],
  },
  {
    label:'Customers',
    icon:'user',
    subItems:[
      {
      label:'Customers',
      link:'/base/customers/customer',
    },
    {
      label:'Customers Roles',
      link:'/base/customers/customer-roles',
    },
  {
    label:'Online Customers',
    link:'/base/customers/online-customers',
  },
  {
    label:'Activity Log',
    link:'/base/customers/activity-log',
  },
  {
    label:'Activity Types',
    link:'/base/customers/activity-types',
  },
  ]
  },
  {
    label:'Promotions',
    icon:'tag',
    subItems:[
      {
        label:'Discounts',
        link:'',
      },
      {
        label:'Affiliates',
        link:'',
      },
      {
        label:'Newsletter Subscribers',
        link:'',
      },
      {
        label:'Campaign',
        link:'',
      }
    ]
  },
  {
    label:'Content Management',
    icon:'codesandbox',
    subItems:[
      {
        label:'Topic(pages)',
        link:'/base/content-management/topic'
      },
      {
        label:'Message Templates',
        link:'/base/content-management/message-templates',
      },
      {
        label:'News Items',
        link:'/base/content-management/new-items'
      },
      {
        label:'News Comments',
        link:'/base/content-management/news-comments'
      },
      {
        label:'Blog Posts',
        link:'/base/content-management/blog-posts'
      },
      {
        label:'Blog Comments',
        link:'/base/content-management/blog-comments'
      },
      {
        label:'Polls',
        link:''
      },
      {
        label:'Forums',
        link:''
      }
    ]
  },
  {
    label:'Configuration',
    icon:'settings',
    subItems:[
      {
        label:'Settings',
        subItems:[
          {
            label:'General Settings'
          },
        ]
    }]
  }
];